import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Logo from '../../assets/img/logo-small1a.png';
import LogoBsDesign from '../../assets/img/logo2.png';
import LogoSticky from '../../assets/img/logo-small1a.png';
import { useWindowWidth } from '../../utils/useWidth';

const buttons = [
  {
    id: 1,
    name: "Home",
    link: "/",
    type: "primary",
  },
  {
    id: 2,
    name: "Contatti",
    link: "/contact",
    type: "primary",
  },
  {
    id: 3,
    name: "Prodotti",
    link: "/products",
    type: "primary",
  },
  {
    id: 4,
    name: "Servizi",
    link: "/services",
    type: "primary",
  },
  {
    id: 5,
    name: "Colori & Finiture",
    link: "/colors",
    type: "primary",
  },
  {
    id: 6,
    name: "Chiamaci Ora",
    link: "tel:+390631054072",
    type: "gg",
  }
];

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const width = useWindowWidth();
  const breakpoint = 1090; // Define your breakpoint in pixels

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setIsScrolled(isScrolled);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed top-0 left-0 w-full z-40 flex items-center justify-between py-8 px-6 lg:px-24 ${isScrolled ? 'bg-[#1f2127] h-16' : 'bg-transparent h-209'}`}>
      <div className="logo inline-flex space-x-2 items-center">
        <Link to={"/"} className="block">
          <img src={isScrolled ? LogoSticky : Logo} alt="Logo" width={200} />
        </Link>
        {/* vertical divider */}

        <div className={`w-px h-8 bg-[#9ca3af]`}></div>
        
        <Link to={"https://bsdesign.it"} className="inline-flex items-center space-x-2 rounded-lg px-6 py-1">
          <img src={LogoBsDesign} alt="Logo" width={40} /> 
          <p className='text-3xl text-white inria-serif-regular'>Design</p>
        </Link>
      </div>
      {width < breakpoint ? (
        <>
          <div>
            <button onClick={() => setIsOpen(!isOpen)} className={`relative z-20 flex items-center justify-center rounded w-[35px] h-[35px] transform transition-all ring-0 ring-gray-300 hover:ring-8 focus:ring-8 ring-opacity-30 duration-200 shadow-md ${isOpen ? 'hidden' : 'block'} ${isScrolled ? 'bg-gray-300' : 'bg-[#333333]'}`}>
              <div className="flex flex-col justify-between w-[14px] h-[14px] transform transition-all duration-300 group-focus:-rotate-[45deg] origin-center">
                <div className={`${isScrolled ? 'bg-black' : 'bg-white'} h-[1.4px] w-1/3 rounded transform transition-all duration-300 group-focus:-rotate-90 group-focus:h-[0.7px] origin-right delay-75 group-focus:-translate-y-[0.7px]`}></div>
                <div className={`${isScrolled ? 'bg-black' : 'bg-white'} h-[0.7px] rounded`}></div>
                <div className={`${isScrolled ? 'bg-black' : 'bg-white'} h-[1.4px] w-1/3 rounded self-end transform transition-all duration-300 group-focus:-rotate-90 group-focus:h-[0.7px] origin-left delay-75 group-focus:translate-y-[0.7px]`}></div>
              </div>
            </button>
          </div>
          <div className={`fixed right-0 top-0 h-full w-64 bg-[#1f2127] transform transition-transform duration-200 ease-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <button onClick={() => setIsOpen(!isOpen)} className="absolute top-0 right-0 p-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <nav className="mt-8">
              {buttons.map((button) => (
                <div key={button.id} className="text-gray-200 font-medium text-[15px] py-2 px-4 border-b border-gray-500">
                  <Link to={button.link} onClick={() => setIsOpen(false)}>{button.name}</Link>
                </div>
              ))}
            </nav>
            <footer className="absolute bottom-0 w-full text-gray-700 font-medium text-[15px] py-2 px-4 border-t border-gray-200">
              <p className='text-center'>© {new Date().getFullYear()} Marrocco Group.</p>
            </footer>
          </div>
        </>
      ) : (
        <div className={`buttons flex items-center space-x-4 font-medium text-[15px] ${isScrolled ? 'text-[#9ca3af]' : 'text-white'}`}>
          {buttons.map((button) => (
            <div key={button.id}>
              {button.type === "primary" ? (
                button.link.startsWith('#') ? (
                  <a href={button.link} className="group transition duration-300 hover:text-gray-300">
                    {button.name}
                    <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-gray-300"></span>
                  </a>
                ) : (
                  <Link to={button.link} className="group transition duration-300 hover:text-gray-300">
                    {button.name}
                    <span className="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-gray-300"></span>
                  </Link>
                )
              ) : (
                button.link.startsWith('#') ? (
                  <a href={button.link} className="group transition duration-300">
                    <div className="bg-[#00a7ec] px-2.5 py-1.5 rounded-full hover:bg-[#006eec] text-white">
                      {button.name}
                    </div>
                  </a>
                ) : (
                  <Link to={button.link} className="group transition duration-300">
                    <div className="bg-[#00a7ec] px-2.5 py-1.5 rounded-full hover:bg-[#006eec] text-white">
                      {button.name}
                    </div>
                  </Link>
                )
              )}
            </div>
          ))}
        </div>
      )}
    </header>
  );
}